/* 8cc3fc1880ee307461cfb900d5f8ee988ff55f0c
 * This file is automatically generated by graphql-let. */

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** A date-time string at UTC, such as 2019-12-03T09:54:33Z, compliant with the date-time format. */
  DateTime: any;
  /** The `Upload` scalar type represents a file upload. */
  Upload: any;
};

export enum AcceptanceStatus {
  Accepted = 'ACCEPTED',
  Assigned = 'ASSIGNED',
  Rejected = 'REJECTED',
  ToBeAssigned = 'TO_BE_ASSIGNED'
}

export enum AmountWork {
  Large = 'LARGE',
  Medium = 'MEDIUM',
  Small = 'SMALL'
}

export type ApplicationCharacteristic = {
  __typename?: 'ApplicationCharacteristic';
  createdAt: Scalars['DateTime'];
  description?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  name: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};

export type ApplicationCharacteristicCreateInput = {
  description?: Maybe<Scalars['String']>;
  name: Scalars['String'];
};

export type ApplicationCharacteristicOrderByInput = {
  createdAt?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type ApplicationCharacteristicUpdateInput = {
  description?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

export type ApplicationCharacteristicWhereInput = {
  AND?: Maybe<Array<SettingsWhereInput>>;
  OR?: Maybe<Array<SettingsWhereInput>>;
  createdAt?: Maybe<DateTimeFilter>;
  name?: Maybe<StringNullableFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
};

export type ApplicationCharacteristicWhereUniqueInput = {
  id: Scalars['Int'];
};

export type Attachment = {
  __typename?: 'Attachment';
  createdAt: Scalars['DateTime'];
  id: Scalars['Int'];
  name?: Maybe<Scalars['String']>;
  updatedAt: Scalars['DateTime'];
  url: Scalars['String'];
};

export type BoolFilter = {
  equals?: Maybe<Scalars['Boolean']>;
  not?: Maybe<BoolFilter>;
};

export type Comment = {
  __typename?: 'Comment';
  createdAt: Scalars['DateTime'];
  documentText: DocumentText;
  id: Scalars['Int'];
  text: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  user: User;
};

export type CommentCreateInput = {
  documentText: ConnectDocumentText;
  text: Scalars['String'];
};

export type CommentOrderByInput = {
  createdAt?: Maybe<SortOrder>;
};

export type CommentWhereInput = {
  AND?: Maybe<Array<CommentWhereInput>>;
  OR?: Maybe<Array<CommentWhereInput>>;
  createdAt?: Maybe<DateTimeFilter>;
  read?: Maybe<BoolFilter>;
  user?: Maybe<UserWhereInput>;
};

export type Company = {
  __typename?: 'Company';
  address?: Maybe<Scalars['String']>;
  btwNumber?: Maybe<Scalars['String']>;
  companyUnits: Array<CompanyUnit>;
  country?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  documentsSubjectTo?: Maybe<Array<Document>>;
  employees?: Maybe<Array<Employee>>;
  expectedMaturityLevel?: Maybe<Scalars['Float']>;
  id: Scalars['Int'];
  kvkNumber?: Maybe<Scalars['String']>;
  logoAttachment?: Maybe<Attachment>;
  measurements: Array<Measurement>;
  name: Scalars['String'];
  notes?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  postalCodePlace?: Maybe<Scalars['String']>;
  remarks?: Maybe<Scalars['String']>;
  responsibleEmployee?: Maybe<Employee>;
  responsibleEmployeeId?: Maybe<Scalars['Int']>;
  telephoneNumber?: Maybe<Scalars['String']>;
  types: Array<CompanyType>;
  updatedAt: Scalars['DateTime'];
};

export type CompanyCreateInput = {
  address?: Maybe<Scalars['String']>;
  btwNumber?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  documentsSubjectTo?: Maybe<ConnectDocuments>;
  expectedMaturityLevel?: Maybe<Scalars['Float']>;
  kvkNumber?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  notes?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  postalCodePlace?: Maybe<Scalars['String']>;
  remarks?: Maybe<Scalars['String']>;
  responsibleEmployee?: Maybe<ConnectEmployee>;
  telephoneNumber?: Maybe<Scalars['String']>;
  types: ConnectCompanyTypes;
};

export type CompanyEmployeeWhereInput = {
  OR?: Maybe<Array<CompanyEmployeeWhereInput>>;
  firstName?: Maybe<StringNullableFilter>;
  lastName?: Maybe<StringNullableFilter>;
  position?: Maybe<StringNullableFilter>;
};

export type CompanyOrderByInput = {
  address?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  postalCode?: Maybe<SortOrder>;
  postalCodePlace?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type CompanyTermDescriptionUpdateInput = {
  text: Scalars['String'];
};

export type CompanyType = {
  __typename?: 'CompanyType';
  createdAt: Scalars['DateTime'];
  description?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  name: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};

export type CompanyTypeFilter = {
  equals?: Maybe<CompanyTypeNames>;
};

export type CompanyTypeListRelationFilter = {
  every?: Maybe<CompanyTypeWhereInput>;
  none?: Maybe<CompanyTypeWhereInput>;
  some?: Maybe<CompanyTypeWhereInput>;
};

export enum CompanyTypeNames {
  Customer = 'CUSTOMER',
  NewsPublisher = 'NEWS_PUBLISHER',
  Other = 'OTHER',
  Publisher = 'PUBLISHER'
}

export type CompanyTypeOrderByInput = {
  createdAt?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type CompanyTypeWhereInput = {
  AND?: Maybe<Array<CompanyTypeWhereInput>>;
  OR?: Maybe<Array<CompanyTypeWhereInput>>;
  createdAt?: Maybe<DateTimeFilter>;
  name?: Maybe<CompanyTypeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
};

export type CompanyTypeWhereUniqueInput = {
  id: Scalars['Int'];
};

export type CompanyUnit = {
  __typename?: 'CompanyUnit';
  acceptanceRemark?: Maybe<Scalars['String']>;
  acceptanceStatus: AcceptanceStatus;
  assignedEmployee?: Maybe<Employee>;
  assignedEmployeeId?: Maybe<Scalars['Int']>;
  averageLastMeasurementsAvailableProducts?: Maybe<Scalars['Float']>;
  averageLastMeasurementsValue?: Maybe<Scalars['Float']>;
  children: Array<CompanyUnit>;
  childrenImplementations: Array<Implementation>;
  code?: Maybe<Scalars['String']>;
  company: Company;
  createdAt: Scalars['DateTime'];
  description?: Maybe<Scalars['String']>;
  employees: Array<Employee>;
  explanation?: Maybe<Scalars['String']>;
  hasImplementations: Scalars['Boolean'];
  hasResponsibilityOverCompanyUnit: Scalars['Boolean'];
  id: Scalars['Int'];
  implementations: Array<Implementation>;
  lastMeasurement?: Maybe<Measurement>;
  link?: Maybe<Scalars['String']>;
  measurementValue?: Maybe<Scalars['Float']>;
  name: Scalars['String'];
  parent?: Maybe<CompanyUnit>;
  parentId?: Maybe<Scalars['Int']>;
  responsibilityEditRight: EditRight;
  sortOrder: Scalars['Int'];
  type?: Maybe<Scalars['String']>;
  updatedAt: Scalars['DateTime'];
};

export type CompanyUnitCreateInput = {
  acceptanceStatus?: Maybe<AcceptanceStatus>;
  assignedEmployee?: Maybe<ConnectEmployee>;
  description?: Maybe<Scalars['String']>;
  employees: ConnectEmployees;
  explanation?: Maybe<Scalars['String']>;
  link?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  parentId?: Maybe<Scalars['Int']>;
  type: Scalars['String'];
};

export type CompanyUnitFilter = {
  none?: Maybe<CompanyUnitWhereInput>;
  some?: Maybe<CompanyUnitWhereInput>;
};

export type CompanyUnitOrderByInput = {
  code?: Maybe<SortOrder>;
  companyId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  type?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type CompanyUnitPositionUpdateInput = {
  id: Scalars['Int'];
  parentId?: Maybe<Scalars['Int']>;
  sortOrder: Scalars['Int'];
};

export type CompanyUnitUpdateInput = {
  acceptanceStatus?: Maybe<AcceptanceStatus>;
  assignedEmployee?: Maybe<ConnectEmployee>;
  description?: Maybe<Scalars['String']>;
  employees: SetEmployees;
  explanation?: Maybe<Scalars['String']>;
  link?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
};

export type CompanyUnitWhereInput = {
  AND?: Maybe<Array<CompanyUnitWhereInput>>;
  OR?: Maybe<Array<CompanyUnitWhereInput>>;
  code?: Maybe<StringNullableFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  name?: Maybe<StringNullableFilter>;
  parentId?: Maybe<IntNullableFilter>;
  type?: Maybe<StringNullableFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
};

export type CompanyUnitWhereUniqueInput = {
  id: Scalars['Int'];
};

export type CompanyUnitWhereWithChildrenFilterInput = {
  AND?: Maybe<Array<CompanyUnitWhereInput>>;
  OR?: Maybe<Array<CompanyUnitWhereInput>>;
  children?: Maybe<CompanyUnitFilter>;
  code?: Maybe<StringNullableFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  name?: Maybe<StringNullableFilter>;
  parentId?: Maybe<IntNullableFilter>;
  type?: Maybe<StringNullableFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
};

export type CompanyUpdateInput = {
  address?: Maybe<Scalars['String']>;
  btwNumber?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  documentsSubjectTo?: Maybe<SetDocuments>;
  expectedMaturityLevel?: Maybe<Scalars['Float']>;
  kvkNumber?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  postalCodePlace?: Maybe<Scalars['String']>;
  remarks?: Maybe<Scalars['String']>;
  responsibleEmployee?: Maybe<ConnectEmployee>;
  telephoneNumber?: Maybe<Scalars['String']>;
  types?: Maybe<SetCompanyTypes>;
};

export type CompanyUpdateWithoutTypesInput = {
  address?: Maybe<Scalars['String']>;
  btwNumber?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  documentsSubjectTo?: Maybe<ConnectDocuments>;
  expectedMaturityLevel?: Maybe<Scalars['Float']>;
  kvkNumber?: Maybe<Scalars['String']>;
  logoAttachment?: Maybe<Array<Scalars['Upload']>>;
  name?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  postalCodePlace?: Maybe<Scalars['String']>;
  remarks?: Maybe<Scalars['String']>;
  responsibleEmployee?: Maybe<ConnectEmployee>;
  telephoneNumber?: Maybe<Scalars['String']>;
};

export type CompanyWhereInput = {
  AND?: Maybe<Array<CompanyWhereInput>>;
  OR?: Maybe<Array<CompanyWhereInput>>;
  address?: Maybe<StringNullableFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  name?: Maybe<StringNullableFilter>;
  postalCode?: Maybe<StringNullableFilter>;
  postalCodePlace?: Maybe<StringNullableFilter>;
  types?: Maybe<CompanyTypeListRelationFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
};

export type CompanyWhereUniqueInput = {
  id: Scalars['Int'];
};

export type ConnectApplicationCharacteristics = {
  connect?: Maybe<Array<ApplicationCharacteristicWhereUniqueInput>>;
};

export type ConnectCompanies = {
  connect?: Maybe<Array<CompanyWhereUniqueInput>>;
};

export type ConnectCompany = {
  connect: CompanyWhereUniqueInput;
  disconnect?: Maybe<Scalars['Boolean']>;
};

export type ConnectCompanyTypes = {
  connect?: Maybe<Array<CompanyTypeWhereUniqueInput>>;
};

export type ConnectCompanyUnit = {
  connect?: Maybe<CompanyUnitWhereUniqueInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
};

export type ConnectCompanyUnits = {
  connect?: Maybe<Array<CompanyUnitWhereUniqueInput>>;
};

export type ConnectDocumentText = {
  connect?: Maybe<DocumentTextWhereUniqueInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
};

export type ConnectDocumentType = {
  connect?: Maybe<DocumentTypeWhereUniqueInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
};

export type ConnectDocuments = {
  connect?: Maybe<Array<DocumentConnect>>;
};

export type ConnectEmployee = {
  connect?: Maybe<EmployeeWhereUniqueInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
};

export type ConnectEmployees = {
  connect?: Maybe<Array<EmployeeWhereUniqueInput>>;
};

export type ConnectEunomeaState = {
  connect?: Maybe<EunomeaStateWhereUniqueInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
};

export type ConnectImplementation = {
  connect?: Maybe<ImplementationWhereUniqueInput>;
};

export type ConnectImplementations = {
  connect?: Maybe<Array<ImplementationWhereUniqueInput>>;
};

export type ConnectLifecycleState = {
  connect?: Maybe<LifecycleStateWhereUniqueInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
};

export type ConnectMeasurement = {
  connect?: Maybe<MeasurementWhereUniqueInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
};

export type ConnectNewsCategory = {
  connect?: Maybe<NewsCategoryWhereUniqueInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
};

export type ConnectOnlyEmployee = {
  connect?: Maybe<EmployeeWhereUniqueInput>;
};

export type ConnectOnlyPolicy = {
  connect?: Maybe<PolicyWhereUniqueInput>;
};

export type ConnectPolicies = {
  connect?: Maybe<Array<PolicyWhereUniqueInput>>;
};

export type ConnectPolicy = {
  connect?: Maybe<PolicyWhereUniqueInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
};

export type ConnectTextInterpretationState = {
  connect?: Maybe<TextInterpretationStateWhereUniqueInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
};

export type ConnectTextInterpretations = {
  connect?: Maybe<Array<TextInterpretationWhereUniqueInput>>;
};

export type ConnectUser = {
  connect?: Maybe<UserWhereUniqueInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
};

export enum CustomerRole {
  Auditor = 'AUDITOR',
  CompanyAdmin = 'COMPANY_ADMIN',
  User = 'USER'
}


export type DateTimeFilter = {
  equals?: Maybe<Scalars['DateTime']>;
  gt?: Maybe<Scalars['DateTime']>;
  gte?: Maybe<Scalars['DateTime']>;
  in?: Maybe<Array<Scalars['DateTime']>>;
  lt?: Maybe<Scalars['DateTime']>;
  lte?: Maybe<Scalars['DateTime']>;
  notIn?: Maybe<Array<Scalars['DateTime']>>;
};

export type Description = {
  __typename?: 'Description';
  createdAt: Scalars['DateTime'];
  id: Scalars['Int'];
  source: Scalars['String'];
  term: Term;
  text: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};

export type DescriptionCreateInput = {
  source: Scalars['String'];
  text: Scalars['String'];
};

export type DescriptionCreateManyInput = {
  create?: Maybe<Array<DescriptionCreateInput>>;
};

export type DescriptionCreateOrUpdateManyInput = {
  create?: Maybe<Array<DescriptionCreateInput>>;
  update?: Maybe<Array<DescriptionUpdateWithWhereUniqueInput>>;
};

export type DescriptionUpdateInput = {
  source?: Maybe<Scalars['String']>;
  text?: Maybe<Scalars['String']>;
};

export type DescriptionUpdateWithWhereUniqueInput = {
  data: DescriptionUpdateInput;
  where: DescriptionWhereUniqueInput;
};

export type DescriptionWhereUniqueInput = {
  id: Scalars['Int'];
};

export type Document = {
  __typename?: 'Document';
  abbreviation?: Maybe<Scalars['String']>;
  companies?: Maybe<Array<Company>>;
  copyrightComment?: Maybe<Scalars['String']>;
  copyrighted: Scalars['Boolean'];
  createdAt: Scalars['DateTime'];
  defaultCharacteristics?: Maybe<Array<ApplicationCharacteristic>>;
  description?: Maybe<Scalars['String']>;
  eunomeaState?: Maybe<EunomeaState>;
  expertUser?: Maybe<User>;
  explanation?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  lastReviewDate?: Maybe<Scalars['DateTime']>;
  lifecycleState?: Maybe<LifecycleState>;
  name?: Maybe<Scalars['String']>;
  optionalCharacteristics?: Maybe<Array<ApplicationCharacteristic>>;
  reviewUser?: Maybe<User>;
  scope?: Maybe<Scalars['String']>;
  shortName: Scalars['String'];
  sourceAttachment?: Maybe<Attachment>;
  sourceLocation?: Maybe<Scalars['String']>;
  stakeholders?: Maybe<Array<Company>>;
  startingDate?: Maybe<Scalars['DateTime']>;
  stateExplanation?: Maybe<Scalars['String']>;
  texts?: Maybe<Array<DocumentText>>;
  type?: Maybe<DocumentType>;
  updatedAt: Scalars['DateTime'];
  version?: Maybe<Scalars['String']>;
};

export type DocumentConnect = {
  id: Scalars['Int'];
};

export type DocumentCreateInput = {
  abbreviation?: Maybe<Scalars['String']>;
  companies?: Maybe<ConnectCompanies>;
  copyrightComment?: Maybe<Scalars['String']>;
  copyrighted?: Maybe<Scalars['Boolean']>;
  defaultCharacteristics?: Maybe<ConnectApplicationCharacteristics>;
  description?: Maybe<Scalars['String']>;
  eunomeaState?: Maybe<ConnectEunomeaState>;
  expertUser?: Maybe<ConnectUser>;
  explanation?: Maybe<Scalars['String']>;
  lastReviewDate?: Maybe<Scalars['DateTime']>;
  lifecycleState?: Maybe<ConnectLifecycleState>;
  name?: Maybe<Scalars['String']>;
  optionalCharacteristics?: Maybe<ConnectApplicationCharacteristics>;
  reviewUser?: Maybe<ConnectUser>;
  scope?: Maybe<Scalars['String']>;
  shortName: Scalars['String'];
  sourceAttachment?: Maybe<Array<Scalars['Upload']>>;
  sourceLocation?: Maybe<Scalars['String']>;
  stakeholders?: Maybe<ConnectCompanies>;
  startingDate?: Maybe<Scalars['DateTime']>;
  stateExplanation?: Maybe<Scalars['String']>;
  type?: Maybe<ConnectDocumentType>;
  version?: Maybe<Scalars['String']>;
};

export type DocumentOrderByInput = {
  createdAt?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  shortName?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type DocumentText = {
  __typename?: 'DocumentText';
  attachment?: Maybe<Attachment>;
  children: Array<DocumentText>;
  code: Scalars['String'];
  comments: Array<Comment>;
  content?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  document: Document;
  documentId: Scalars['Int'];
  explanation?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  implementations: Array<Implementation>;
  implementationsSunburst: Array<Implementation>;
  improvementValue?: Maybe<Scalars['Float']>;
  interpretations?: Maybe<Array<TextInterpretation>>;
  measurementValue?: Maybe<Scalars['Float']>;
  name: Scalars['String'];
  parent?: Maybe<DocumentText>;
  state?: Maybe<TextInterpretationState>;
  stateExplanation?: Maybe<Scalars['String']>;
  type: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};

export type DocumentTextImportInput = {
  documentId: Scalars['Int'];
  file: Scalars['Upload'];
};

export type DocumentTextOrderByInput = {
  code?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  sortOrder?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type DocumentTextReviewerUpdateInput = {
  state: ConnectTextInterpretationState;
  stateExplanation?: Maybe<Scalars['String']>;
};

export type DocumentTextUpdateInput = {
  attachment?: Maybe<Array<Scalars['Upload']>>;
  content?: Maybe<Scalars['String']>;
  explanation?: Maybe<Scalars['String']>;
  interpretations?: Maybe<TextInterpretationUpdateManyInput>;
  name?: Maybe<Scalars['String']>;
  state: ConnectTextInterpretationState;
  stateExplanation?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
};

export type DocumentTextWhereInput = {
  AND?: Maybe<Array<DocumentTextWhereInput>>;
  OR?: Maybe<Array<DocumentTextWhereInput>>;
  content?: Maybe<StringNullableFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  documentId?: Maybe<Scalars['Int']>;
  explanation?: Maybe<StringNullableFilter>;
  name?: Maybe<StringNullableFilter>;
  parentHierarchyCode?: Maybe<StringNullableFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
};

export type DocumentTextWhereUniqueInput = {
  id?: Maybe<Scalars['Int']>;
};

export type DocumentType = {
  __typename?: 'DocumentType';
  createdAt: Scalars['DateTime'];
  description?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  name: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};

export type DocumentTypeCreateInput = {
  description?: Maybe<Scalars['String']>;
  name: Scalars['String'];
};

export type DocumentTypeOrderByInput = {
  createdAt?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type DocumentTypeUpdateInput = {
  description?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

export type DocumentTypeWhereInput = {
  AND?: Maybe<Array<SettingsWhereInput>>;
  OR?: Maybe<Array<SettingsWhereInput>>;
  createdAt?: Maybe<DateTimeFilter>;
  name?: Maybe<StringNullableFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
};

export type DocumentTypeWhereUniqueInput = {
  id: Scalars['Int'];
};

export type DocumentUpdateInput = {
  abbreviation?: Maybe<Scalars['String']>;
  companies?: Maybe<SetCompanies>;
  copyrightComment?: Maybe<Scalars['String']>;
  copyrighted?: Maybe<Scalars['Boolean']>;
  defaultCharacteristics?: Maybe<SetApplicationCharacteristics>;
  description?: Maybe<Scalars['String']>;
  eunomeaState?: Maybe<ConnectEunomeaState>;
  expertUser?: Maybe<ConnectUser>;
  explanation?: Maybe<Scalars['String']>;
  lastReviewDate?: Maybe<Scalars['DateTime']>;
  lifecycleState?: Maybe<ConnectLifecycleState>;
  name?: Maybe<Scalars['String']>;
  optionalCharacteristics?: Maybe<SetApplicationCharacteristics>;
  reviewUser?: Maybe<ConnectUser>;
  scope?: Maybe<Scalars['String']>;
  shortName?: Maybe<Scalars['String']>;
  sourceAttachment?: Maybe<Array<Scalars['Upload']>>;
  sourceLocation?: Maybe<Scalars['String']>;
  stakeholders?: Maybe<SetCompanies>;
  startingDate?: Maybe<Scalars['DateTime']>;
  stateExplanation?: Maybe<Scalars['String']>;
  type?: Maybe<ConnectDocumentType>;
  version?: Maybe<Scalars['String']>;
};

export type DocumentWhereInput = {
  AND?: Maybe<Array<DocumentWhereInput>>;
  OR?: Maybe<Array<DocumentWhereInput>>;
  createdAt?: Maybe<DateTimeFilter>;
  name?: Maybe<StringNullableFilter>;
  shortName?: Maybe<StringNullableFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
};

export type DocumentWhereUniqueInput = {
  id?: Maybe<Scalars['Int']>;
  shortName?: Maybe<Scalars['String']>;
};

export enum EditRight {
  Accept = 'ACCEPT',
  Read = 'READ',
  Remove = 'REMOVE',
  Select = 'SELECT'
}

export type Employee = {
  __typename?: 'Employee';
  assignedCompanyUnits: Array<CompanyUnit>;
  company: Company;
  companyId: Scalars['Int'];
  companyUnits: Array<CompanyUnit>;
  createdAt: Scalars['DateTime'];
  firstName?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  implementations: Array<Implementation>;
  improvements: Array<Improvement>;
  lastName?: Maybe<Scalars['String']>;
  lastNamePrefix?: Maybe<Scalars['String']>;
  position?: Maybe<Scalars['String']>;
  responsibilities: Array<Responsibility>;
  updatedAt: Scalars['DateTime'];
  user?: Maybe<User>;
  userId?: Maybe<Scalars['Int']>;
};

export type EmployeeCreateInput = {
  company: ConnectCompany;
  companyUnits?: Maybe<ConnectCompanyUnits>;
  firstName?: Maybe<Scalars['String']>;
  lastName: Scalars['String'];
  lastNamePrefix?: Maybe<Scalars['String']>;
  position: Scalars['String'];
  user?: Maybe<ConnectUser>;
};

export type EmployeeCreateWithoutCompanyInput = {
  companyUnits?: Maybe<ConnectCompanyUnits>;
  firstName?: Maybe<Scalars['String']>;
  lastName: Scalars['String'];
  lastNamePrefix?: Maybe<Scalars['String']>;
  position: Scalars['String'];
  user: UserCreateOneWithCustomerRole;
};

export type EmployeeOrderByInput = {
  companyId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  firstName?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  lastName?: Maybe<SortOrder>;
  lastNamePrefix?: Maybe<SortOrder>;
  position?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  userId?: Maybe<SortOrder>;
};

export type EmployeeUpdateInput = {
  companyUnits?: Maybe<SetCompanyUnits>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  lastNamePrefix?: Maybe<Scalars['String']>;
  position?: Maybe<Scalars['String']>;
  user?: Maybe<ConnectUser>;
};

export type EmployeeUpdateWithoutCompanyInput = {
  companyUnits?: Maybe<SetCompanyUnits>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  lastNamePrefix?: Maybe<Scalars['String']>;
  position?: Maybe<Scalars['String']>;
  user: UserUpdateOneWithoutEmployeeInput;
};

export type EmployeeWhereInput = {
  AND?: Maybe<Array<EmployeeWhereInput>>;
  OR?: Maybe<Array<EmployeeWhereInput>>;
  companyId: Scalars['Float'];
  createdAt?: Maybe<DateTimeFilter>;
  firstName?: Maybe<StringNullableFilter>;
  lastName?: Maybe<StringNullableFilter>;
  lastNamePrefix?: Maybe<StringNullableFilter>;
  position?: Maybe<StringNullableFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
};

export type EmployeeWhereUniqueInput = {
  id: Scalars['Int'];
};

export type EunomeaState = {
  __typename?: 'EunomeaState';
  createdAt: Scalars['DateTime'];
  description?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  name: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};

export type EunomeaStateCreateInput = {
  description?: Maybe<Scalars['String']>;
  name: Scalars['String'];
};

export type EunomeaStateOrderByInput = {
  createdAt?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type EunomeaStateUpdateInput = {
  description?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

export type EunomeaStateWhereInput = {
  AND?: Maybe<Array<SettingsWhereInput>>;
  OR?: Maybe<Array<SettingsWhereInput>>;
  createdAt?: Maybe<DateTimeFilter>;
  name?: Maybe<StringNullableFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
};

export type EunomeaStateWhereUniqueInput = {
  id: Scalars['Int'];
};

export enum ExplainType {
  NonRelevant = 'NON_RELEVANT',
  NotImplementable = 'NOT_IMPLEMENTABLE',
  NoRisk = 'NO_RISK'
}

export type Implementation = {
  __typename?: 'Implementation';
  acceptanceRemark?: Maybe<Scalars['String']>;
  acceptanceStatus: AcceptanceStatus;
  assignedEmployee?: Maybe<Employee>;
  assignedEmployeeId?: Maybe<Scalars['Int']>;
  burdenOfProof?: Maybe<Scalars['String']>;
  code: Scalars['String'];
  company: Company;
  companyUnit?: Maybe<CompanyUnit>;
  companyUnitId?: Maybe<Scalars['Int']>;
  createdAt: Scalars['DateTime'];
  description?: Maybe<Scalars['String']>;
  exhibits: Array<ImplementationExhibit>;
  explain: Scalars['Boolean'];
  explainDescription?: Maybe<Scalars['String']>;
  explainExpiration?: Maybe<Scalars['DateTime']>;
  explainType?: Maybe<ExplainType>;
  explanation?: Maybe<Scalars['String']>;
  hasResponsibilityOverImplementation: Scalars['Boolean'];
  id: Scalars['Int'];
  implementationInput: Array<Implementation>;
  implementationOutput: Array<Implementation>;
  improvementValue?: Maybe<Scalars['Float']>;
  improvements?: Maybe<Array<Improvement>>;
  jurisprudences: Array<Jurisprudence>;
  key: Scalars['String'];
  level: Scalars['Float'];
  measurementValue?: Maybe<Scalars['Float']>;
  measurements: Array<Measurement>;
  name: Scalars['String'];
  newsMessages: Array<NewsMessage>;
  policy: Policy;
  policyId: Scalars['Int'];
  responsibilityEditRight: EditRight;
  results?: Maybe<Array<Policy>>;
  scope?: Maybe<Scalars['String']>;
  sortOrder: Scalars['Int'];
  updatedAt: Scalars['DateTime'];
};


export type ImplementationMeasurementsArgs = {
  take?: Maybe<Scalars['Int']>;
};

export type ImplementationCreateInput = {
  acceptanceStatus?: Maybe<AcceptanceStatus>;
  assignedEmployee?: Maybe<ConnectEmployee>;
  burdenOfProof?: Maybe<Scalars['String']>;
  companyUnit?: Maybe<ConnectCompanyUnit>;
  description?: Maybe<Scalars['String']>;
  explain?: Maybe<Scalars['Boolean']>;
  explainDescription?: Maybe<Scalars['String']>;
  explainExpiration?: Maybe<Scalars['DateTime']>;
  explainType?: Maybe<ExplainType>;
  explanation?: Maybe<Scalars['String']>;
  implementationInput?: Maybe<ConnectImplementations>;
  name: Scalars['String'];
  policy: ConnectOnlyPolicy;
  scope?: Maybe<Scalars['String']>;
  sortOrder?: Maybe<Scalars['Int']>;
};

export type ImplementationDeleteInput = {
  id: Scalars['Int'];
  policyId: Scalars['Int'];
  sortOrder: Scalars['Int'];
};

export type ImplementationExhibit = {
  __typename?: 'ImplementationExhibit';
  createdAt: Scalars['DateTime'];
  id: Scalars['Int'];
  implementation: Implementation;
  location: Scalars['String'];
  name: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};

export type ImplementationExhibitCreateInput = {
  location: Scalars['String'];
  name: Scalars['String'];
};

export type ImplementationExhibitInput = {
  create?: Maybe<Array<ImplementationExhibitCreateInput>>;
  delete?: Maybe<ImplementationExhibitWhereUniqueInput>;
  update?: Maybe<Array<ImplementationExhibitUpdateWithWhereUniqueInput>>;
};

export type ImplementationExhibitUpdateInput = {
  location?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

export type ImplementationExhibitUpdateWithWhereUniqueInput = {
  data: ImplementationExhibitUpdateInput;
  where: ImplementationExhibitWhereUniqueInput;
};

export type ImplementationExhibitWhereUniqueInput = {
  id: Scalars['Int'];
};

export type ImplementationUpdateInput = {
  acceptanceStatus?: Maybe<AcceptanceStatus>;
  assignedEmployee?: Maybe<ConnectEmployee>;
  burdenOfProof?: Maybe<Scalars['String']>;
  companyUnit?: Maybe<ConnectCompanyUnit>;
  description?: Maybe<Scalars['String']>;
  exhibits?: Maybe<ImplementationExhibitInput>;
  explain?: Maybe<Scalars['Boolean']>;
  explainDescription?: Maybe<Scalars['String']>;
  explainExpiration?: Maybe<Scalars['DateTime']>;
  explainType?: Maybe<ExplainType>;
  explanation?: Maybe<Scalars['String']>;
  implementationInput?: Maybe<ConnectImplementations>;
  name?: Maybe<Scalars['String']>;
  scope?: Maybe<Scalars['String']>;
  sortOrder?: Maybe<Scalars['Int']>;
};

export type ImplementationWhereUniqueInput = {
  id: Scalars['Int'];
};

export type Improvement = {
  __typename?: 'Improvement';
  actions?: Maybe<Scalars['String']>;
  amountWork: AmountWork;
  company: Company;
  createdAt: Scalars['DateTime'];
  expectedDeliveryDate: Scalars['DateTime'];
  hasResponsibilityOverImprovement: Scalars['Boolean'];
  id: Scalars['Int'];
  implementation?: Maybe<Implementation>;
  implementationId: Scalars['Float'];
  measurement?: Maybe<Measurement>;
  name: Scalars['String'];
  progress: Scalars['Int'];
  remarks?: Maybe<Scalars['String']>;
  responsibleEmployee?: Maybe<Employee>;
  result: Scalars['String'];
  status: ImprovementStatus;
  ticketId?: Maybe<Scalars['String']>;
  updatedAt: Scalars['DateTime'];
};

export type ImprovementCreateInput = {
  actions?: Maybe<Scalars['String']>;
  amountWork: AmountWork;
  expectedDeliveryDate: Scalars['DateTime'];
  implementation: ConnectImplementation;
  measurement?: Maybe<ConnectMeasurement>;
  name: Scalars['String'];
  progress: Scalars['Int'];
  remarks?: Maybe<Scalars['String']>;
  responsibleEmployee?: Maybe<ConnectEmployee>;
  result: Scalars['String'];
  status: ImprovementStatus;
  ticketId?: Maybe<Scalars['String']>;
};

export enum ImprovementStatus {
  Executing = 'EXECUTING',
  Finished = 'FINISHED',
  Paused = 'PAUSED',
  Stopped = 'STOPPED',
  ToStart = 'TO_START'
}

export type ImprovementUpdateInput = {
  actions?: Maybe<Scalars['String']>;
  amountWork?: Maybe<AmountWork>;
  expectedDeliveryDate?: Maybe<Scalars['DateTime']>;
  implementation?: Maybe<ConnectImplementation>;
  measurement?: Maybe<ConnectMeasurement>;
  name?: Maybe<Scalars['String']>;
  progress?: Maybe<Scalars['Int']>;
  remarks?: Maybe<Scalars['String']>;
  responsibleEmployee?: Maybe<ConnectEmployee>;
  result?: Maybe<Scalars['String']>;
  status?: Maybe<ImprovementStatus>;
  ticketId?: Maybe<Scalars['String']>;
};

export type ImprovementWhereUniqueInput = {
  id: Scalars['Int'];
};

export type IntNullableFilter = {
  equals?: Maybe<Scalars['Int']>;
  gt?: Maybe<Scalars['Int']>;
  gte?: Maybe<Scalars['Int']>;
  in?: Maybe<Array<Scalars['Int']>>;
  lt?: Maybe<Scalars['Int']>;
  lte?: Maybe<Scalars['Int']>;
  not?: Maybe<IntNullableFilter>;
  notIn?: Maybe<Array<Scalars['Int']>>;
};

export type Jurisprudence = {
  __typename?: 'Jurisprudence';
  caseIdentifier: Scalars['String'];
  context?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  dateOfVerdict: Scalars['DateTime'];
  documents?: Maybe<Array<Document>>;
  id: Scalars['Int'];
  policies?: Maybe<Array<Policy>>;
  summary?: Maybe<Scalars['String']>;
  updatedAt: Scalars['DateTime'];
};

export type JurisprudenceCreateInput = {
  caseIdentifier: Scalars['String'];
  context?: Maybe<Scalars['String']>;
  dateOfVerdict: Scalars['DateTime'];
  documents?: Maybe<ConnectDocuments>;
  policies?: Maybe<ConnectPolicies>;
  summary?: Maybe<Scalars['String']>;
};

export type JurisprudenceOrderByInput = {
  caseIdentifier?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  dateOfVerdict?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type JurisprudenceUpdateInput = {
  caseIdentifier?: Maybe<Scalars['String']>;
  context?: Maybe<Scalars['String']>;
  dateOfVerdict?: Maybe<Scalars['DateTime']>;
  documents?: Maybe<SetDocuments>;
  policies?: Maybe<SetPolicies>;
  summary?: Maybe<Scalars['String']>;
};

export type JurisprudenceWhereInput = {
  AND?: Maybe<Array<JurisprudenceWhereInput>>;
  OR?: Maybe<Array<JurisprudenceWhereInput>>;
  caseIdentifier?: Maybe<StringNullableFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  dateOfVerdict?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
};

export type JurisprudenceWhereUniqueInput = {
  caseIdentifier?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
};

export enum Layout {
  Cursive = 'CURSIVE',
  Fat = 'FAT',
  RedLetters = 'RED_LETTERS',
  Underline = 'UNDERLINE',
  YellowBackground = 'YELLOW_BACKGROUND'
}

export type LifecycleState = {
  __typename?: 'LifecycleState';
  createdAt: Scalars['DateTime'];
  description?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  name: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};

export type LifecycleStateCreateInput = {
  description?: Maybe<Scalars['String']>;
  name: Scalars['String'];
};

export type LifecycleStateOrderByInput = {
  createdAt?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type LifecycleStateUpdateInput = {
  description?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

export type LifecycleStateWhereInput = {
  AND?: Maybe<Array<SettingsWhereInput>>;
  OR?: Maybe<Array<SettingsWhereInput>>;
  createdAt?: Maybe<DateTimeFilter>;
  name?: Maybe<StringNullableFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
};

export type LifecycleStateWhereUniqueInput = {
  id: Scalars['Int'];
};

export type Measurement = {
  __typename?: 'Measurement';
  available?: Maybe<Scalars['Float']>;
  availableResults?: Maybe<Array<Scalars['Int']>>;
  average?: Maybe<Scalars['Float']>;
  comment?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  createdByEmployee?: Maybe<Employee>;
  id: Scalars['Int'];
  implementation: Implementation;
  implementationId: Scalars['Int'];
  improvements?: Maybe<Array<Improvement>>;
  question1?: Maybe<Scalars['Float']>;
  question2?: Maybe<Scalars['Float']>;
  question3?: Maybe<Scalars['Float']>;
  question4?: Maybe<Scalars['Float']>;
  question5?: Maybe<Scalars['Float']>;
  question6?: Maybe<Scalars['Float']>;
  question7?: Maybe<Scalars['Float']>;
  question8?: Maybe<Scalars['Float']>;
  question9?: Maybe<Scalars['Float']>;
  question10?: Maybe<Scalars['Float']>;
  unavailableResults?: Maybe<Array<Scalars['Int']>>;
  updatedAt: Scalars['DateTime'];
};

export type MeasurementCreateInput = {
  availableResults?: Maybe<Array<Scalars['Int']>>;
  comment?: Maybe<Scalars['String']>;
  question1?: Maybe<Scalars['Float']>;
  question2?: Maybe<Scalars['Float']>;
  question3?: Maybe<Scalars['Float']>;
  question4?: Maybe<Scalars['Float']>;
  question5?: Maybe<Scalars['Float']>;
  question6?: Maybe<Scalars['Float']>;
  question7?: Maybe<Scalars['Float']>;
  question8?: Maybe<Scalars['Float']>;
  question9?: Maybe<Scalars['Float']>;
  question10?: Maybe<Scalars['Float']>;
  unavailableResults?: Maybe<Array<Scalars['Int']>>;
};

export type MeasurementDeleteInput = {
  id: Scalars['Int'];
};

export type MeasurementWhereUniqueInput = {
  id: Scalars['Int'];
};

export type Mutation = {
  __typename?: 'Mutation';
  acceptCompanyUnitResponsibility: CompanyUnit;
  acceptImplementationResponsibility: Implementation;
  acceptResponsibility: Responsibility;
  addComment: Comment;
  addMeasurement: Implementation;
  changePassword: Scalars['Boolean'];
  createApplicationCharacteristic: ApplicationCharacteristic;
  createColleague: Employee;
  createCompany: Company;
  createCompanyUnit: CompanyUnit;
  createDocument: Document;
  createDocumentType: DocumentType;
  createEmployee: Employee;
  createEunomeaState: EunomeaState;
  createImplementation: Implementation;
  createImplementationResult: Scalars['Boolean'];
  createImprovement: Improvement;
  createJurisprudence: Jurisprudence;
  createLifecycleState: LifecycleState;
  createNewsCategory: NewsCategory;
  createNewsMessage: NewsMessage;
  createPolicy: Policy;
  createPolicyCompanySetting: Policy;
  createResponsibility: Responsibility;
  createTerm: Term;
  createTextInterpretationState: TextInterpretationState;
  createUser: User;
  deleteApplicationCharacteristic: ApplicationCharacteristic;
  deleteColleague: Employee;
  deleteCompany: Company;
  deleteCompanyUnit: CompanyUnit;
  deleteDescription: Description;
  deleteDocument: Document;
  deleteDocumentType: DocumentType;
  deleteEmployee: Employee;
  deleteEunomeaState: EunomeaState;
  deleteImplementation: Scalars['Boolean'];
  deleteImplementationResult: Scalars['Boolean'];
  deleteImprovement: Improvement;
  deleteJurisprudence: Jurisprudence;
  deleteLifecycleState: LifecycleState;
  deleteMeasurement: Scalars['Boolean'];
  deleteNewsCategory: NewsCategory;
  deleteNewsMessage: NewsMessage;
  deletePolicy: Scalars['Boolean'];
  deletePolicyCompanySetting: Policy;
  deleteTerm: Term;
  deleteTextInterpretation: TextInterpretation;
  deleteTextInterpretationState: TextInterpretationState;
  deleteUser: User;
  importDocumentTexts: Scalars['Int'];
  login: Session;
  loginAs: Session;
  logout: Scalars['Boolean'];
  markCommentsAsRead: Scalars['Boolean'];
  moveTextInterpretations: Scalars['Boolean'];
  rejectCompanyUnitResponsibility: CompanyUnit;
  rejectImplementationResponsibility: Implementation;
  rejectResponsibility: Responsibility;
  removeCompanyUnitResponsibility: CompanyUnit;
  removeImplementationResponsibility: Implementation;
  removeResponsibility: Responsibility;
  setPassword: User;
  setPreferredAccountPage: User;
  totpEnable: Session;
  totpSetup: TotpSetup;
  totpVerify: SessionWithTrustToken;
  updateApplicationCharacteristic: ApplicationCharacteristic;
  updateColleague: Employee;
  updateCompany: Company;
  updateCompanyUnit: CompanyUnit;
  updateCompanyUnitPosition: Scalars['Boolean'];
  updateCurrentCompany: Company;
  updateDocument: Document;
  updateDocumentText: DocumentText;
  updateDocumentTextForReviewers: DocumentText;
  updateDocumentType: DocumentType;
  updateEmployee: Employee;
  updateEunomeaState: EunomeaState;
  updateImplementation: Implementation;
  updateImprovement: Improvement;
  updateJurisprudence: Jurisprudence;
  updateLifecycleState: LifecycleState;
  updateNewsCategory: NewsCategory;
  updateNewsMessage: NewsMessage;
  updatePolicy: Policy;
  updatePolicyCompanySetting: Policy;
  updatePolicyPosition: Scalars['Boolean'];
  updateResponsibility: Responsibility;
  updateTerm: Term;
  updateTextInterpretationState: TextInterpretationState;
  updateUser: User;
  upsertCompanyTermDescription: Term;
};


export type MutationAcceptCompanyUnitResponsibilityArgs = {
  id: Scalars['Int'];
};


export type MutationAcceptImplementationResponsibilityArgs = {
  implementationId: Scalars['Int'];
};


export type MutationAcceptResponsibilityArgs = {
  policyId: Scalars['Int'];
};


export type MutationAddCommentArgs = {
  data: CommentCreateInput;
};


export type MutationAddMeasurementArgs = {
  data: MeasurementCreateInput;
  where: ImplementationWhereUniqueInput;
};


export type MutationChangePasswordArgs = {
  newPassword: Scalars['String'];
  oldPassword: Scalars['String'];
};


export type MutationCreateApplicationCharacteristicArgs = {
  data: ApplicationCharacteristicCreateInput;
};


export type MutationCreateColleagueArgs = {
  data: EmployeeCreateWithoutCompanyInput;
};


export type MutationCreateCompanyArgs = {
  data: CompanyCreateInput;
};


export type MutationCreateCompanyUnitArgs = {
  data: CompanyUnitCreateInput;
};


export type MutationCreateDocumentArgs = {
  data: DocumentCreateInput;
};


export type MutationCreateDocumentTypeArgs = {
  data: DocumentTypeCreateInput;
};


export type MutationCreateEmployeeArgs = {
  data: EmployeeCreateInput;
};


export type MutationCreateEunomeaStateArgs = {
  data: EunomeaStateCreateInput;
};


export type MutationCreateImplementationArgs = {
  data: ImplementationCreateInput;
};


export type MutationCreateImplementationResultArgs = {
  implementationId: Scalars['Int'];
  resultId: Scalars['Int'];
};


export type MutationCreateImprovementArgs = {
  data: ImprovementCreateInput;
};


export type MutationCreateJurisprudenceArgs = {
  data: JurisprudenceCreateInput;
};


export type MutationCreateLifecycleStateArgs = {
  data: LifecycleStateCreateInput;
};


export type MutationCreateNewsCategoryArgs = {
  data: NewsCategoryCreateInput;
};


export type MutationCreateNewsMessageArgs = {
  data: NewsMessageCreateInput;
};


export type MutationCreatePolicyArgs = {
  data: PolicyCreateInput;
};


export type MutationCreatePolicyCompanySettingArgs = {
  data: PolicyCompanySettingCreateInput;
  policyId: Scalars['Int'];
};


export type MutationCreateResponsibilityArgs = {
  data: ResponsibilityCreateInput;
  policyId: Scalars['Int'];
};


export type MutationCreateTermArgs = {
  data: TermCreateInput;
};


export type MutationCreateTextInterpretationStateArgs = {
  data: TextInterpretationStateCreateInput;
};


export type MutationCreateUserArgs = {
  data: UserCreateInput;
};


export type MutationDeleteApplicationCharacteristicArgs = {
  where: ApplicationCharacteristicWhereUniqueInput;
};


export type MutationDeleteColleagueArgs = {
  where: EmployeeWhereUniqueInput;
};


export type MutationDeleteCompanyArgs = {
  where: CompanyWhereUniqueInput;
};


export type MutationDeleteCompanyUnitArgs = {
  where: CompanyUnitWhereUniqueInput;
};


export type MutationDeleteDescriptionArgs = {
  where: DescriptionWhereUniqueInput;
};


export type MutationDeleteDocumentArgs = {
  where: DocumentWhereUniqueInput;
};


export type MutationDeleteDocumentTypeArgs = {
  where: DocumentTypeWhereUniqueInput;
};


export type MutationDeleteEmployeeArgs = {
  where: EmployeeWhereUniqueInput;
};


export type MutationDeleteEunomeaStateArgs = {
  where: EunomeaStateWhereUniqueInput;
};


export type MutationDeleteImplementationArgs = {
  data: ImplementationDeleteInput;
};


export type MutationDeleteImplementationResultArgs = {
  implementationId: Scalars['Int'];
  resultId: Scalars['Int'];
};


export type MutationDeleteImprovementArgs = {
  where: ImprovementWhereUniqueInput;
};


export type MutationDeleteJurisprudenceArgs = {
  where: JurisprudenceWhereUniqueInput;
};


export type MutationDeleteLifecycleStateArgs = {
  where: LifecycleStateWhereUniqueInput;
};


export type MutationDeleteMeasurementArgs = {
  data: MeasurementDeleteInput;
};


export type MutationDeleteNewsCategoryArgs = {
  where: NewsCategoryWhereUniqueInput;
};


export type MutationDeleteNewsMessageArgs = {
  where: NewsMessageWhereUniqueInput;
};


export type MutationDeletePolicyArgs = {
  data: PolicyDeleteInput;
};


export type MutationDeletePolicyCompanySettingArgs = {
  policyId: Scalars['Int'];
};


export type MutationDeleteTermArgs = {
  where: TermWhereUniqueInput;
};


export type MutationDeleteTextInterpretationArgs = {
  where: TextInterpretationWhereUniqueInput;
};


export type MutationDeleteTextInterpretationStateArgs = {
  where: TextInterpretationStateWhereUniqueInput;
};


export type MutationDeleteUserArgs = {
  where: UserWhereUniqueInput;
};


export type MutationImportDocumentTextsArgs = {
  data: DocumentTextImportInput;
};


export type MutationLoginArgs = {
  email: Scalars['String'];
  password: Scalars['String'];
};


export type MutationLoginAsArgs = {
  email: Scalars['String'];
};


export type MutationMarkCommentsAsReadArgs = {
  documentTextId: Scalars['Int'];
};


export type MutationMoveTextInterpretationsArgs = {
  interpretationIds: Array<Scalars['Int']>;
  policyId: Scalars['Int'];
};


export type MutationRejectCompanyUnitResponsibilityArgs = {
  acceptanceRemark: Scalars['String'];
  id: Scalars['Int'];
};


export type MutationRejectImplementationResponsibilityArgs = {
  acceptanceRemark: Scalars['String'];
  implementationId: Scalars['Int'];
};


export type MutationRejectResponsibilityArgs = {
  acceptanceRemark: Scalars['String'];
  policyId: Scalars['Int'];
};


export type MutationRemoveCompanyUnitResponsibilityArgs = {
  id: Scalars['Int'];
};


export type MutationRemoveImplementationResponsibilityArgs = {
  implementationId: Scalars['Int'];
};


export type MutationRemoveResponsibilityArgs = {
  policyId: Scalars['Int'];
};


export type MutationSetPasswordArgs = {
  newPassword: Scalars['String'];
};


export type MutationSetPreferredAccountPageArgs = {
  preferredAccountPage: PreferredAccountPage;
};


export type MutationTotpEnableArgs = {
  code: Scalars['String'];
};


export type MutationTotpVerifyArgs = {
  code: Scalars['String'];
  trustDevice: Scalars['Boolean'];
};


export type MutationUpdateApplicationCharacteristicArgs = {
  data: ApplicationCharacteristicUpdateInput;
  where: ApplicationCharacteristicWhereUniqueInput;
};


export type MutationUpdateColleagueArgs = {
  data: EmployeeUpdateWithoutCompanyInput;
  where: EmployeeWhereUniqueInput;
};


export type MutationUpdateCompanyArgs = {
  data: CompanyUpdateInput;
  where: CompanyWhereUniqueInput;
};


export type MutationUpdateCompanyUnitArgs = {
  data: CompanyUnitUpdateInput;
  where: CompanyUnitWhereUniqueInput;
};


export type MutationUpdateCompanyUnitPositionArgs = {
  data: CompanyUnitPositionUpdateInput;
};


export type MutationUpdateCurrentCompanyArgs = {
  data: CompanyUpdateWithoutTypesInput;
};


export type MutationUpdateDocumentArgs = {
  data: DocumentUpdateInput;
  where: DocumentWhereUniqueInput;
};


export type MutationUpdateDocumentTextArgs = {
  data: DocumentTextUpdateInput;
  where: DocumentTextWhereUniqueInput;
};


export type MutationUpdateDocumentTextForReviewersArgs = {
  data: DocumentTextReviewerUpdateInput;
  where: DocumentTextWhereUniqueInput;
};


export type MutationUpdateDocumentTypeArgs = {
  data: DocumentTypeUpdateInput;
  where: DocumentTypeWhereUniqueInput;
};


export type MutationUpdateEmployeeArgs = {
  data: EmployeeUpdateInput;
  where: EmployeeWhereUniqueInput;
};


export type MutationUpdateEunomeaStateArgs = {
  data: EunomeaStateUpdateInput;
  where: EunomeaStateWhereUniqueInput;
};


export type MutationUpdateImplementationArgs = {
  data: ImplementationUpdateInput;
  where: ImplementationWhereUniqueInput;
};


export type MutationUpdateImprovementArgs = {
  data: ImprovementUpdateInput;
  where: ImprovementWhereUniqueInput;
};


export type MutationUpdateJurisprudenceArgs = {
  data: JurisprudenceUpdateInput;
  where: JurisprudenceWhereUniqueInput;
};


export type MutationUpdateLifecycleStateArgs = {
  data: LifecycleStateUpdateInput;
  where: LifecycleStateWhereUniqueInput;
};


export type MutationUpdateNewsCategoryArgs = {
  data: NewsCategoryUpdateInput;
  where: NewsCategoryWhereUniqueInput;
};


export type MutationUpdateNewsMessageArgs = {
  data: NewsMessageUpdateInput;
  where: NewsMessageWhereUniqueInput;
};


export type MutationUpdatePolicyArgs = {
  data: PolicyUpdateInput;
  where: PolicyWhereUniqueInput;
};


export type MutationUpdatePolicyCompanySettingArgs = {
  data: PolicyCompanySettingUpdateInput;
  policyId: Scalars['Int'];
};


export type MutationUpdatePolicyPositionArgs = {
  data: PolicyUpdatePositionInput;
};


export type MutationUpdateResponsibilityArgs = {
  data: ResponsibilityUpdateInput;
  policyId: Scalars['Int'];
};


export type MutationUpdateTermArgs = {
  data: TermUpdateInput;
  where: TermWhereUniqueInput;
};


export type MutationUpdateTextInterpretationStateArgs = {
  data: TextInterpretationStateUpdateInput;
  where: TextInterpretationStateWhereUniqueInput;
};


export type MutationUpdateUserArgs = {
  data: UserUpdateInput;
  where: UserWhereUniqueInput;
};


export type MutationUpsertCompanyTermDescriptionArgs = {
  data: CompanyTermDescriptionUpdateInput;
  where: TermWhereUniqueInput;
};

export type NewsCategory = {
  __typename?: 'NewsCategory';
  createdAt: Scalars['DateTime'];
  description?: Maybe<Scalars['String']>;
  documents?: Maybe<Array<NewsMessage>>;
  id: Scalars['Int'];
  name: Scalars['String'];
  newsMessages?: Maybe<Array<NewsCategory>>;
  updatedAt: Scalars['DateTime'];
};

export type NewsCategoryCreateInput = {
  description?: Maybe<Scalars['String']>;
  name: Scalars['String'];
};

export type NewsCategoryOrderByInput = {
  createdAt?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type NewsCategoryUpdateInput = {
  description?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

export type NewsCategoryWhereInput = {
  AND?: Maybe<Array<NewsCategoryWhereInput>>;
  OR?: Maybe<Array<NewsCategoryWhereInput>>;
  createdAt?: Maybe<DateTimeFilter>;
  documentId?: Maybe<Scalars['Int']>;
  name?: Maybe<StringNullableFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
};

export type NewsCategoryWhereUniqueInput = {
  id: Scalars['Int'];
};

export type NewsMessage = {
  __typename?: 'NewsMessage';
  createdAt: Scalars['DateTime'];
  description: Scalars['String'];
  documents?: Maybe<Array<Document>>;
  id: Scalars['Int'];
  link?: Maybe<Scalars['String']>;
  newsCategory?: Maybe<NewsCategory>;
  newsType: NewsType;
  policies: Array<Policy>;
  published: Scalars['DateTime'];
  source: Company;
  subject: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};

export type NewsMessageCreateInput = {
  description: Scalars['String'];
  documents?: Maybe<ConnectDocuments>;
  link?: Maybe<Scalars['String']>;
  newsCategory?: Maybe<ConnectNewsCategory>;
  newsType: NewsType;
  policies?: Maybe<ConnectPolicies>;
  published: Scalars['DateTime'];
  source: ConnectCompany;
  subject: Scalars['String'];
};

export type NewsMessageOrderByInput = {
  createdAt?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  published?: Maybe<SortOrder>;
  subject?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type NewsMessageUpdateInput = {
  description?: Maybe<Scalars['String']>;
  documents?: Maybe<SetDocuments>;
  link?: Maybe<Scalars['String']>;
  newsCategory?: Maybe<ConnectNewsCategory>;
  newsType?: Maybe<NewsType>;
  policies?: Maybe<SetPolicies>;
  published?: Maybe<Scalars['DateTime']>;
  source?: Maybe<ConnectCompany>;
  subject?: Maybe<Scalars['String']>;
};

export type NewsMessageWhereInput = {
  AND?: Maybe<Array<NewsMessageWhereInput>>;
  OR?: Maybe<Array<NewsMessageWhereInput>>;
  createdAt?: Maybe<DateTimeFilter>;
  published?: Maybe<DateTimeFilter>;
  subject?: Maybe<StringNullableFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
};

export type NewsMessageWhereUniqueInput = {
  id: Scalars['Int'];
};

export enum NewsType {
  Jurisprudence = 'JURISPRUDENCE',
  Literature = 'LITERATURE',
  Newsletter = 'NEWSLETTER'
}

export enum Periodic {
  Advised = 'ADVISED',
  EventBased = 'EVENT_BASED',
  Mandatory = 'MANDATORY',
  No = 'NO',
  Optional = 'OPTIONAL'
}

export type Policy = {
  __typename?: 'Policy';
  availableResults: Array<Policy>;
  children: Array<Policy>;
  code: Scalars['String'];
  createdAt: Scalars['DateTime'];
  description?: Maybe<Scalars['String']>;
  documentInterpretations: Array<PolicyDocumentInterpretations>;
  explanation?: Maybe<Scalars['String']>;
  graphLabel?: Maybe<Scalars['String']>;
  hasDocumentInterpretations: Scalars['Boolean'];
  hasResponsibilityOverPolicy: Scalars['Boolean'];
  id: Scalars['Int'];
  implementationResults?: Maybe<Array<Implementation>>;
  implementations?: Maybe<Array<Implementation>>;
  inputFrom: Array<Policy>;
  interpretations?: Maybe<Array<TextInterpretation>>;
  jurisprudences?: Maybe<Array<Jurisprudence>>;
  key: Scalars['String'];
  level: Scalars['Float'];
  measurements: Array<Measurement>;
  name: Scalars['String'];
  newsMessages?: Maybe<Array<NewsMessage>>;
  outputTo: Array<Policy>;
  parent?: Maybe<Policy>;
  parentId?: Maybe<Scalars['Int']>;
  policyCompanySetting?: Maybe<PolicyCompanySetting>;
  rank: Rank;
  responsibility?: Maybe<Responsibility>;
  sortOrder: Scalars['Int'];
  strategy?: Maybe<Scalars['String']>;
  updatedAt: Scalars['DateTime'];
};


export type PolicyDocumentInterpretationsArgs = {
  nested?: Maybe<Scalars['Boolean']>;
};


export type PolicyMeasurementsArgs = {
  take?: Maybe<Scalars['Int']>;
};

export type PolicyCompanySetting = {
  __typename?: 'PolicyCompanySetting';
  company: Company;
  createdAt: Scalars['DateTime'];
  description?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  policy: Policy;
  updatedAt: Scalars['DateTime'];
};

export type PolicyCompanySettingCreateInput = {
  description?: Maybe<Scalars['String']>;
};

export type PolicyCompanySettingUpdateInput = {
  description?: Maybe<Scalars['String']>;
};

export type PolicyCreateInput = {
  description?: Maybe<Scalars['String']>;
  explanation?: Maybe<Scalars['String']>;
  graphLabel?: Maybe<Scalars['String']>;
  inputFrom?: Maybe<ConnectPolicies>;
  name: Scalars['String'];
  parent?: Maybe<ConnectPolicy>;
  rank?: Maybe<Rank>;
  sortOrder?: Maybe<Scalars['Int']>;
  strategy?: Maybe<Scalars['String']>;
};

export type PolicyDeleteInput = {
  id: Scalars['Int'];
  parentId?: Maybe<Scalars['Int']>;
  sortOrder?: Maybe<Scalars['Int']>;
};

export type PolicyDocumentInterpretations = {
  __typename?: 'PolicyDocumentInterpretations';
  documentId: Scalars['Int'];
  interpretations?: Maybe<Array<TextInterpretation>>;
  name?: Maybe<Scalars['String']>;
  shortName: Scalars['String'];
};

export type PolicyOrderByInput = {
  createdAt?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  sortOrder?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type PolicyUpdateInput = {
  description?: Maybe<Scalars['String']>;
  explanation?: Maybe<Scalars['String']>;
  graphLabel?: Maybe<Scalars['String']>;
  inputFrom?: Maybe<SetPolicies>;
  name?: Maybe<Scalars['String']>;
  parent?: Maybe<ConnectPolicy>;
  rank?: Maybe<Rank>;
  sortOrder?: Maybe<Scalars['Int']>;
  strategy?: Maybe<Scalars['String']>;
};

export type PolicyUpdatePositionInput = {
  id: Scalars['Int'];
  parentId?: Maybe<Scalars['Int']>;
  sortOrder?: Maybe<Scalars['Int']>;
};

export type PolicyWhereInput = {
  AND?: Maybe<Array<PolicyWhereInput>>;
  OR?: Maybe<Array<PolicyWhereInput>>;
  createdAt?: Maybe<DateTimeFilter>;
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<StringNullableFilter>;
  parentId?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<DateTimeFilter>;
};

export type PolicyWhereUniqueInput = {
  id: Scalars['Int'];
};

export enum PreferredAccountPage {
  ControlFramework = 'CONTROL_FRAMEWORK',
  PersonalDashboard = 'PERSONAL_DASHBOARD'
}

export type Query = {
  __typename?: 'Query';
  applicationCharacteristic?: Maybe<ApplicationCharacteristic>;
  applicationCharacteristics: Array<ApplicationCharacteristic>;
  colleagues: Array<Employee>;
  comments: Array<Comment>;
  companies: Array<Company>;
  company?: Maybe<Company>;
  companyTypes: Array<CompanyType>;
  companyUnit?: Maybe<CompanyUnit>;
  companyUnits: Array<CompanyUnit>;
  currentCompany?: Maybe<Company>;
  currentUser: User;
  document?: Maybe<Document>;
  documentText?: Maybe<DocumentText>;
  documentTexts: Array<DocumentText>;
  documentType?: Maybe<DocumentType>;
  documentTypes: Array<DocumentType>;
  documents: Array<Document>;
  employee?: Maybe<Employee>;
  employees: Array<Employee>;
  eunomeaState?: Maybe<EunomeaState>;
  eunomeaStates: Array<EunomeaState>;
  implementation?: Maybe<Implementation>;
  improvement?: Maybe<Improvement>;
  jurisprudence?: Maybe<Jurisprudence>;
  jurisprudences: Array<Jurisprudence>;
  lifecycleState?: Maybe<LifecycleState>;
  lifecycleStates: Array<LifecycleState>;
  newsCategories: Array<NewsCategory>;
  newsCategory?: Maybe<NewsCategory>;
  newsMessage?: Maybe<NewsMessage>;
  newsMessages: Array<NewsMessage>;
  policies: Array<Policy>;
  policy?: Maybe<Policy>;
  term?: Maybe<Term>;
  terms: Array<Term>;
  textInterpretation?: Maybe<TextInterpretation>;
  textInterpretationState?: Maybe<TextInterpretationState>;
  textInterpretationStates: Array<TextInterpretationState>;
  textInterpretations: Array<TextInterpretation>;
  user?: Maybe<User>;
  users: Array<User>;
};


export type QueryApplicationCharacteristicArgs = {
  where: ApplicationCharacteristicWhereUniqueInput;
};


export type QueryApplicationCharacteristicsArgs = {
  orderBy?: Maybe<ApplicationCharacteristicOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<ApplicationCharacteristicWhereInput>;
};


export type QueryColleaguesArgs = {
  orderBy?: Maybe<EmployeeOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<CompanyEmployeeWhereInput>;
};


export type QueryCommentsArgs = {
  orderBy?: Maybe<CommentOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<CommentWhereInput>;
};


export type QueryCompaniesArgs = {
  orderBy?: Maybe<CompanyOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<CompanyWhereInput>;
};


export type QueryCompanyArgs = {
  where: CompanyWhereUniqueInput;
};


export type QueryCompanyTypesArgs = {
  orderBy?: Maybe<CompanyTypeOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<CompanyTypeWhereInput>;
};


export type QueryCompanyUnitArgs = {
  where: CompanyUnitWhereUniqueInput;
};


export type QueryCompanyUnitsArgs = {
  orderBy?: Maybe<CompanyUnitOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<CompanyUnitWhereWithChildrenFilterInput>;
};


export type QueryDocumentArgs = {
  where: DocumentWhereUniqueInput;
};


export type QueryDocumentTextArgs = {
  where: DocumentTextWhereUniqueInput;
};


export type QueryDocumentTextsArgs = {
  orderBy?: Maybe<DocumentTextOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<DocumentTextWhereInput>;
};


export type QueryDocumentTypeArgs = {
  where: DocumentTypeWhereUniqueInput;
};


export type QueryDocumentTypesArgs = {
  orderBy?: Maybe<DocumentTypeOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<DocumentTypeWhereInput>;
};


export type QueryDocumentsArgs = {
  orderBy?: Maybe<DocumentOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<DocumentWhereInput>;
};


export type QueryEmployeeArgs = {
  where: EmployeeWhereUniqueInput;
};


export type QueryEmployeesArgs = {
  orderBy?: Maybe<EmployeeOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<EmployeeWhereInput>;
};


export type QueryEunomeaStateArgs = {
  where: EunomeaStateWhereUniqueInput;
};


export type QueryEunomeaStatesArgs = {
  orderBy?: Maybe<EunomeaStateOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<EunomeaStateWhereInput>;
};


export type QueryImplementationArgs = {
  where: ImplementationWhereUniqueInput;
};


export type QueryImprovementArgs = {
  where: ImprovementWhereUniqueInput;
};


export type QueryJurisprudenceArgs = {
  where: JurisprudenceWhereUniqueInput;
};


export type QueryJurisprudencesArgs = {
  orderBy?: Maybe<Array<JurisprudenceOrderByInput>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<JurisprudenceWhereInput>;
};


export type QueryLifecycleStateArgs = {
  where: LifecycleStateWhereUniqueInput;
};


export type QueryLifecycleStatesArgs = {
  orderBy?: Maybe<LifecycleStateOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<LifecycleStateWhereInput>;
};


export type QueryNewsCategoriesArgs = {
  orderBy?: Maybe<NewsCategoryOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<NewsCategoryWhereInput>;
};


export type QueryNewsCategoryArgs = {
  where: NewsCategoryWhereUniqueInput;
};


export type QueryNewsMessageArgs = {
  where: NewsMessageWhereUniqueInput;
};


export type QueryNewsMessagesArgs = {
  orderBy?: Maybe<Array<NewsMessageOrderByInput>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<NewsMessageWhereInput>;
};


export type QueryPoliciesArgs = {
  orderBy?: Maybe<PolicyOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<PolicyWhereInput>;
};


export type QueryPolicyArgs = {
  where: PolicyWhereUniqueInput;
};


export type QueryTermArgs = {
  where: TermWhereUniqueInput;
};


export type QueryTermsArgs = {
  orderBy?: Maybe<TermOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<TermWhereInput>;
};


export type QueryTextInterpretationArgs = {
  where: TextInterpretationWhereUniqueInput;
};


export type QueryTextInterpretationStateArgs = {
  where: TextInterpretationStateWhereUniqueInput;
};


export type QueryTextInterpretationStatesArgs = {
  orderBy?: Maybe<TextInterpretationStateOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<TextInterpretationStateWhereInput>;
};


export type QueryTextInterpretationsArgs = {
  orderBy?: Maybe<TextInterpretationOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<TextInterpretationWhereInput>;
};


export type QueryUserArgs = {
  where: UserWhereUniqueInput;
};


export type QueryUsersArgs = {
  orderBy?: Maybe<UserOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  where?: Maybe<UserWhereInput>;
};

export enum QueryMode {
  Default = 'default',
  Insensitive = 'insensitive'
}

export enum Rank {
  Extreme = 'EXTREME',
  High = 'HIGH',
  Low = 'LOW',
  Medium = 'MEDIUM'
}

export type Responsibility = {
  __typename?: 'Responsibility';
  acceptanceRemark?: Maybe<Scalars['String']>;
  acceptanceStatus: AcceptanceStatus;
  assignedEmployee?: Maybe<Employee>;
  company: Company;
  createdAt: Scalars['DateTime'];
  description?: Maybe<Scalars['String']>;
  editRight: EditRight;
  id: Scalars['Int'];
  policy: Policy;
  remark?: Maybe<Scalars['String']>;
  updatedAt: Scalars['DateTime'];
};

export type ResponsibilityCreateInput = {
  assignedEmployee?: Maybe<ConnectOnlyEmployee>;
  description?: Maybe<Scalars['String']>;
};

export type ResponsibilityUpdateInput = {
  assignedEmployee?: Maybe<ConnectEmployee>;
  description?: Maybe<Scalars['String']>;
};

export enum Role {
  Admin = 'ADMIN',
  Auditor = 'AUDITOR',
  CompanyAdmin = 'COMPANY_ADMIN',
  NewsEditor = 'NEWS_EDITOR',
  Reviewer = 'REVIEWER',
  TermsEditor = 'TERMS_EDITOR',
  User = 'USER'
}

export type Session = {
  __typename?: 'Session';
  createdAt: Scalars['DateTime'];
  id: Scalars['Int'];
  secondFactor: Scalars['Boolean'];
  token: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  user: User;
};

export type SessionWithTrustToken = {
  __typename?: 'SessionWithTrustToken';
  createdAt: Scalars['DateTime'];
  deviceToken?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  secondFactor: Scalars['Boolean'];
  token: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  user: User;
};

export type SetApplicationCharacteristics = {
  set?: Maybe<Array<ApplicationCharacteristicWhereUniqueInput>>;
};

export type SetCompanies = {
  set?: Maybe<Array<CompanyWhereUniqueInput>>;
};

export type SetCompanyTypes = {
  set?: Maybe<Array<CompanyTypeWhereUniqueInput>>;
};

export type SetCompanyUnits = {
  set?: Maybe<Array<CompanyUnitWhereUniqueInput>>;
};

export type SetDocuments = {
  set?: Maybe<Array<DocumentConnect>>;
};

export type SetEmployees = {
  set?: Maybe<Array<EmployeeWhereUniqueInput>>;
};

export type SetPolicies = {
  set?: Maybe<Array<PolicyWhereUniqueInput>>;
};

export type SetTextInterpretations = {
  set?: Maybe<Array<TextInterpretationWhereUniqueInput>>;
};

export type SettingsWhereInput = {
  AND?: Maybe<Array<SettingsWhereInput>>;
  OR?: Maybe<Array<SettingsWhereInput>>;
  createdAt?: Maybe<DateTimeFilter>;
  name?: Maybe<StringNullableFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
};

export enum SortOrder {
  Asc = 'asc',
  Desc = 'desc'
}

export type StringNullableFilter = {
  contains?: Maybe<Scalars['String']>;
  endsWith?: Maybe<Scalars['String']>;
  equals?: Maybe<Scalars['String']>;
  gt?: Maybe<Scalars['String']>;
  gte?: Maybe<Scalars['String']>;
  in?: Maybe<Array<Scalars['String']>>;
  lt?: Maybe<Scalars['String']>;
  lte?: Maybe<Scalars['String']>;
  mode?: Maybe<QueryMode>;
  notIn?: Maybe<Array<Scalars['String']>>;
  startsWith?: Maybe<Scalars['String']>;
};

export type Term = {
  __typename?: 'Term';
  companyTermDescription?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  descriptions?: Maybe<Array<Description>>;
  eunomeaDescription?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  name: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};

export type TermCreateInput = {
  descriptions?: Maybe<DescriptionCreateManyInput>;
  eunomeaDescription?: Maybe<Scalars['String']>;
  name: Scalars['String'];
};

export type TermOrderByInput = {
  createdAt?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type TermUpdateInput = {
  descriptions?: Maybe<DescriptionCreateOrUpdateManyInput>;
  eunomeaDescription?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

export type TermWhereInput = {
  AND?: Maybe<Array<TermWhereInput>>;
  OR?: Maybe<Array<TermWhereInput>>;
  createdAt?: Maybe<DateTimeFilter>;
  name?: Maybe<StringNullableFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
};

export type TermWhereUniqueInput = {
  id: Scalars['Int'];
};

export type TextInterpretation = {
  __typename?: 'TextInterpretation';
  createdAt: Scalars['DateTime'];
  defaultCharacteristics: Array<ApplicationCharacteristic>;
  examples?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  inputFromInterpretations: Array<TextInterpretation>;
  layout?: Maybe<Layout>;
  optionalCharacteristics: Array<ApplicationCharacteristic>;
  outputToInterpretations: Array<TextInterpretation>;
  periodic: Periodic;
  policy?: Maybe<Policy>;
  productResult?: Maybe<Scalars['String']>;
  requirements?: Maybe<Scalars['String']>;
  sortId: Scalars['String'];
  specification: Scalars['String'];
  subject: Scalars['String'];
  text: DocumentText;
  updatedAt: Scalars['DateTime'];
};


export type TextInterpretationPolicyArgs = {
  where?: Maybe<PolicyWhereInput>;
};

export type TextInterpretationCreateInput = {
  defaultCharacteristics?: Maybe<ConnectApplicationCharacteristics>;
  examples?: Maybe<Scalars['String']>;
  inputFrom?: Maybe<ConnectTextInterpretations>;
  layout?: Maybe<Layout>;
  optionalCharacteristics?: Maybe<ConnectApplicationCharacteristics>;
  periodic: Periodic;
  policy?: Maybe<ConnectPolicy>;
  productResult?: Maybe<Scalars['String']>;
  requirements?: Maybe<Scalars['String']>;
  sortId: Scalars['String'];
  specification: Scalars['String'];
  subject: Scalars['String'];
};

export type TextInterpretationOrderByInput = {
  code?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  specification?: Maybe<SortOrder>;
  subject?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type TextInterpretationState = {
  __typename?: 'TextInterpretationState';
  createdAt: Scalars['DateTime'];
  description?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  name: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};

export type TextInterpretationStateCreateInput = {
  description?: Maybe<Scalars['String']>;
  name: Scalars['String'];
};

export type TextInterpretationStateOrderByInput = {
  createdAt?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type TextInterpretationStateUpdateInput = {
  description?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

export type TextInterpretationStateWhereInput = {
  AND?: Maybe<Array<SettingsWhereInput>>;
  OR?: Maybe<Array<SettingsWhereInput>>;
  createdAt?: Maybe<DateTimeFilter>;
  name?: Maybe<StringNullableFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
};

export type TextInterpretationStateWhereUniqueInput = {
  id: Scalars['Int'];
};

export type TextInterpretationUpdateInput = {
  defaultCharacteristics?: Maybe<SetApplicationCharacteristics>;
  examples?: Maybe<Scalars['String']>;
  inputFrom?: Maybe<SetTextInterpretations>;
  layout?: Maybe<Layout>;
  optionalCharacteristics?: Maybe<SetApplicationCharacteristics>;
  periodic?: Maybe<Periodic>;
  policy?: Maybe<ConnectPolicy>;
  productResult?: Maybe<Scalars['String']>;
  requirements?: Maybe<Scalars['String']>;
  sortId?: Maybe<Scalars['String']>;
  specification?: Maybe<Scalars['String']>;
  subject?: Maybe<Scalars['String']>;
};

export type TextInterpretationUpdateManyInput = {
  create?: Maybe<Array<TextInterpretationCreateInput>>;
  update?: Maybe<Array<TextInterpretationUpdateWithWhereUniqueInput>>;
};

export type TextInterpretationUpdateWithWhereUniqueInput = {
  data: TextInterpretationUpdateInput;
  where: TextInterpretationWhereUniqueInput;
};

export type TextInterpretationWhereInput = {
  AND?: Maybe<Array<TextInterpretationWhereInput>>;
  OR?: Maybe<Array<TextInterpretationWhereInput>>;
  createdAt?: Maybe<DateTimeFilter>;
  specification?: Maybe<StringNullableFilter>;
  subject?: Maybe<StringNullableFilter>;
  textId?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<DateTimeFilter>;
};

export type TextInterpretationWhereUniqueInput = {
  id: Scalars['Int'];
};

export type TotpSetup = {
  __typename?: 'TotpSetup';
  email: Scalars['String'];
  secondFactorKey: Scalars['String'];
};


export type User = {
  __typename?: 'User';
  createdAt: Scalars['DateTime'];
  currentSession?: Maybe<Session>;
  email: Scalars['String'];
  employee?: Maybe<Employee>;
  firstName?: Maybe<Scalars['String']>;
  fullName?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  lastName?: Maybe<Scalars['String']>;
  lastNamePrefix?: Maybe<Scalars['String']>;
  preferredAccountPage: PreferredAccountPage;
  role: Role;
  secondFactorEnabled: Scalars['Boolean'];
  updatedAt: Scalars['DateTime'];
};

export type UserCreateInput = {
  email: Scalars['String'];
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  lastNamePrefix?: Maybe<Scalars['String']>;
  password: Scalars['String'];
  role: Role;
};

export type UserCreateOneWithCustomerRole = {
  create: UserCreateWithCustomerRoleInput;
};

export type UserCreateWithCustomerRoleInput = {
  email: Scalars['String'];
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  lastNamePrefix?: Maybe<Scalars['String']>;
  password: Scalars['String'];
  role?: Maybe<CustomerRole>;
};

export type UserOrderByInput = {
  createdAt?: Maybe<SortOrder>;
  email?: Maybe<SortOrder>;
  firstName?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  lastName?: Maybe<SortOrder>;
  lastNamePrefix?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type UserUpdateInput = {
  email?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  lastNamePrefix?: Maybe<Scalars['String']>;
  password?: Maybe<Scalars['String']>;
  role?: Maybe<Role>;
};

export type UserUpdateOneWithoutEmployeeInput = {
  update: UserUpdateWithCustomerRole;
};

export type UserUpdateWithCustomerRole = {
  email?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  lastNamePrefix?: Maybe<Scalars['String']>;
  password?: Maybe<Scalars['String']>;
  role?: Maybe<CustomerRole>;
};

export type UserWhereInput = {
  AND?: Maybe<Array<UserWhereInput>>;
  OR?: Maybe<Array<UserWhereInput>>;
  createdAt?: Maybe<DateTimeFilter>;
  email?: Maybe<StringNullableFilter>;
  firstName?: Maybe<StringNullableFilter>;
  id?: Maybe<IntNullableFilter>;
  lastName?: Maybe<StringNullableFilter>;
  lastNamePrefix?: Maybe<StringNullableFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
};

export type UserWhereUniqueInput = {
  id: Scalars['Int'];
};

export type UpdateEunomeaStateMutationVariables = Exact<{
  data: EunomeaStateUpdateInput;
  id: Scalars['Int'];
}>;


export type UpdateEunomeaStateMutation = (
  { __typename?: 'Mutation' }
  & { updateEunomeaState: (
    { __typename?: 'EunomeaState' }
    & Pick<EunomeaState, 'id' | 'name' | 'description'>
  ) }
);


export const UpdateEunomeaStateDocument = gql`
    mutation updateEunomeaState($data: EunomeaStateUpdateInput!, $id: Int!) {
  updateEunomeaState(data: $data, where: {id: $id}) {
    id
    name
    description
  }
}
    `;
export type UpdateEunomeaStateMutationFn = Apollo.MutationFunction<UpdateEunomeaStateMutation, UpdateEunomeaStateMutationVariables>;

/**
 * __useUpdateEunomeaStateMutation__
 *
 * To run a mutation, you first call `useUpdateEunomeaStateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateEunomeaStateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateEunomeaStateMutation, { data, loading, error }] = useUpdateEunomeaStateMutation({
 *   variables: {
 *      data: // value for 'data'
 *      id: // value for 'id'
 *   },
 * });
 */
export function useUpdateEunomeaStateMutation(baseOptions?: Apollo.MutationHookOptions<UpdateEunomeaStateMutation, UpdateEunomeaStateMutationVariables>) {
        return Apollo.useMutation<UpdateEunomeaStateMutation, UpdateEunomeaStateMutationVariables>(UpdateEunomeaStateDocument, baseOptions);
      }
export type UpdateEunomeaStateMutationHookResult = ReturnType<typeof useUpdateEunomeaStateMutation>;
export type UpdateEunomeaStateMutationResult = Apollo.MutationResult<UpdateEunomeaStateMutation>;
export type UpdateEunomeaStateMutationOptions = Apollo.BaseMutationOptions<UpdateEunomeaStateMutation, UpdateEunomeaStateMutationVariables>;